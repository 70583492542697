import getUserInfo from '../ostium/UserInfo';
import {TypeOcrH4} from './Types';
import {VERSION, PROJECT, BRANCH} from './version';

export default function Title(props: any) {
  const email = getUserInfo().getEmail();
  const perms = getUserInfo().perms.join(',');
  const pipe = email === '' ? '' : ' ' + perms + ' | ';

  return (
    <>
      <TypeOcrH4 sx={{mx: 4, my: 0}} align="center">
        {props.children}
      </TypeOcrH4>
      <p>
        {email + pipe}
        {PROJECT} {BRANCH} v{VERSION}
      </p>
    </>
  );
}
