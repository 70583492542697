import * as React from 'react';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import getUserInfo from '../ostium/UserInfo';
import If from './If';

import Button from '@mui/material/Button';
import {PAGE_SUPPORT, PAGE_LOGIN} from '../const';

interface SupportBreadcrumbsProps {
  setCurrentTab: (s: string) => void;
}
export function SupportBreadcrumbs(props: SupportBreadcrumbsProps) {
  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <Button
          color="primary"
          onClick={() => {
            props.setCurrentTab(PAGE_SUPPORT);
          }}
        >
          Back to Support
        </Button>
      </Breadcrumbs>
    </div>
  );
}

interface LoginButtonProps {
  setCurrentTab: (s: string) => void;
}

export function LoginButton(props: LoginButtonProps) {
  return (
    <div role="presentation">
      <If value={!getUserInfo().loggedIn}>
        <Breadcrumbs aria-label="breadcrumb">
          {' '}
          <Button
            onClick={() => {
              props.setCurrentTab(PAGE_LOGIN);
            }}
            name="Back to Login"
          >
            Go to Login
          </Button>{' '}
        </Breadcrumbs>
      </If>
    </div>
  );
}
